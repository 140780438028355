import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BrowserStorageService, storageKeys } from 'core/browser-storage.service';
import { SignalRService } from 'core/signalr.service';
import { TeamSettings } from 'models';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AboutComponent } from 'shared/about/about.component';
import { InviteModalComponent } from 'shared/invite-modal/invite-modal.component';
import { SwitchTeamModalComponent } from 'shared/switch-team-modal/switch-team-modal.component';
import { AppState, playersActions, sidebarActions, userActions } from 'store';
import { IPlayerLevel } from 'store/players/players.model';
import { loadMatchInvitations, toggleSidebar } from 'store/sidebar/sidebar.actions';
import { selectUnreadCount } from 'store/sidebar/sidebar.reducer';
import { Session } from './../../core/session.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
    public activateRoute: string;
    public showMenu = false;
    public levelInfo$: Observable<IPlayerLevel>;
    public levelTooltip = '';
    public teamSettings$: Observable<TeamSettings>;
    public notificationCount: number;
    public currentPageTitle: string = null;
    private _unsubscribe = new Subject<void>();
    constructor(
        public readonly session: Session,
        private readonly store: Store<AppState>,
        private readonly signalR: SignalRService,
        private readonly translate: TranslateService,
        private readonly http: HttpClient,
        private readonly matDialog: MatDialog,
        private readonly storage: BrowserStorageService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.teamSettings$ = this.store.pipe(select((x) => x.players.teamSettings));
        this.levelInfo$ = this.store.pipe(select((x) => x.players.levelInfo));
        this.store.pipe(select((x) => x.season.active)).subscribe(() => {
            this.store.dispatch(playersActions.loadPlayerLevel());
        });
        this.signalR.newMatch.subscribe(() => {
            this.store.dispatch(playersActions.loadPlayerLevel());
        });

        this.store.pipe(select(selectUnreadCount), takeUntil(this._unsubscribe)).subscribe((count) => {
            this.notificationCount = count;
            if (this.session.pushNotificatiosSupported && !this.session.isSubbscribedToPushNotifications) {
                this.notificationCount++;
            }
        });

        this.store
            .pipe(
                select((x) => x.user.showMenu),
                takeUntil(this._unsubscribe),
            )
            .subscribe((showMenu) => {
                this.showMenu = showMenu;
            });

        this.store.dispatch(sidebarActions.getNotificationCount());

        this.currentPageTitle = this.route.root.firstChild.snapshot.data['pageTitle'] || null;
        this.router.events
            .pipe(
                takeUntil(this._unsubscribe),
                filter((x) => x instanceof NavigationEnd),
            )
            .subscribe(() => {
                this.currentPageTitle = this.route.root.firstChild.snapshot.data['pageTitle'] || null;
            });
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public logout() {
        this.session.logout();
    }

    public switchTeam(id: number) {
        this.session.switchTeam(id);
    }

    public changeLanguage(lang: string) {
        this.storage.local.setItem(storageKeys.lang, lang);
        this.translate.use(lang);
        this.http.patch('/api/players/set-lang', { language: lang.substr(0, 2) }).subscribe();
    }

    public openAbout() {
        this.matDialog.open<AboutComponent>(AboutComponent, { maxWidth: '850px' });
    }
    public openInviteModal() {
        this.matDialog
            .open<InviteModalComponent>(InviteModalComponent, { autoFocus: false })
            .afterClosed()
            .subscribe(() => {
                this.store.dispatch(playersActions.loadPlayers({ force: true }));
            });
    }

    public toggleSidebar() {
        this.store.dispatch(loadMatchInvitations({ lastModified: null }));
        this.store.dispatch(toggleSidebar());
    }

    public openSwitchTeamModal() {
        this.matDialog.open<SwitchTeamModalComponent>(SwitchTeamModalComponent, { panelClass: 'dialog-md' });
    }

    public hideMenu() {
        this.store.dispatch(userActions.setMenuVisibility({ showMenu: false }));
    }

    public createNewTeam() {
        this.http.delete('/api/teams/clear-selected').subscribe(() => {
            window.location.href = '/account/create/2';
        });
    }
}
