<div class="l-topbar container-fluid">
    <div class="container topbar">
        <div class="topbar_logo d-none d-xxl-block" [routerLink]="['dashboard']">{{ session.user.teamName }}</div>
        <div class="topbar_logo d-xxl-none" [routerLink]="['dashboard']">{{ (currentPageTitle | translate) || session.user.teamName }}</div>

        <div class="menu" [class.show]="showMenu">
            <button
                mat-button
                class="menu_link"
                [routerLink]="['/dashboard']"
                [routerLinkActive]="'is-selected dashboard'"
                (click)="hideMenu()"
            >
                <mat-icon class="icon">home</mat-icon>
                {{ 'NAVBAR.DASHBOARD' | translate }}
            </button>
            <button
                class="menu_link"
                mat-button
                [routerLink]="['/players']"
                [routerLinkActive]="'is-selected players'"
                (click)="hideMenu()"
            >
                <mat-icon class="icon">people</mat-icon>
                {{ 'NAVBAR.PLAYERS' | translate }}
            </button>
            <button
                class="menu_link"
                mat-button
                [routerLink]="['/achievements']"
                [routerLinkActive]="'is-selected achievements'"
                (click)="hideMenu()"
            >
                <mat-icon class="icon">emoji_events</mat-icon>
                {{ 'NAVBAR.ACHIEVEMENTS' | translate }}
            </button>
            <button
                class="menu_link"
                mat-button
                [routerLink]="['/statistics']"
                [routerLinkActive]="'is-selected statistics'"
                (click)="hideMenu()"
            >
                <mat-icon class="icon">table_chart</mat-icon>
                {{ 'NAVBAR.STATISTICS' | translate }}
            </button>
            <button
                class="menu_link"
                mat-button
                [routerLink]="['/hall-of-shame']"
                [routerLinkActive]="'is-selected hall-of-shame'"
                (click)="hideMenu()"
            >
                <mat-icon class="icon">stars</mat-icon>
                {{ 'NAVBAR.HALL_OF_SHAME' | translate }}
            </button>
            <div class="menu_divider d-xxl-none"></div>
            <button class="menu_link d-xxl-none d-inline-flex" mat-button (click)="hideMenu(); toggleSidebar()">
                <mat-icon [matBadge]="notificationCount | badgeNumber" mat-button class="icon">
                    <mat-icon>notifications_active</mat-icon>
                </mat-icon>
                {{ 'NAVBAR.NOTIFICATIONS' | translate }}
            </button>
            <button
                class="menu_link last-xxl-link d-inline-flex"
                mat-button
                [routerLink]="['/settings']"
                [routerLinkActive]="'is-selected'"
                (click)="hideMenu()"
            >
                <mat-icon class="icon">settings</mat-icon>
                {{ 'NAVBAR.SETTINGS' | translate }}
            </button>

            <button
                *ngIf="session.user?.teams?.length > 1"
                class="menu_link d-xxl-none d-inline-flex"
                mat-button
                (click)="openSwitchTeamModal()"
            >
                <mat-icon mat-button class="icon">
                    <mat-icon>follow_the_signs</mat-icon>
                </mat-icon>
                {{ 'NAVBAR.CHANGE_TEAM' | translate }}
            </button>

            <button class="menu_link d-xxl-none d-inline-flex" (click)="logout()" mat-button>
                <mat-icon class="icon">exit_to_app</mat-icon>
                {{ 'NAVBAR.LOGOUT' | translate }}
            </button>
        </div>
        <div class="topbar_user">
            <div class="d-inline clickable" [matMenuTriggerFor]="appMenu">
                <span
                    placement="bottom"
                    *ngIf="levelInfo$ | async as info; else onlyPlayer"
                    [matTooltip]="'Level: ' + info.level + ' (' + info.experiencePoints + ' / ' + info.nextLevelExp + ')'"
                    >{{ session.user.displayName }}</span
                >
                <div *ngIf="levelInfo$ | async as info" [style.width.%]="info.levelPercent" class="xp"></div>

                <ng-template #onlyPlayer>
                    <span placement="bottom"> {{ session.user.displayName }} </span>
                </ng-template>
            </div>
            <button class="settings-link" mat-icon-button [matMenuTriggerFor]="appMenu">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
        </div>

        <mat-menu #appMenu="matMenu">
            <button routerLink="/settings" mat-menu-item><mat-icon>settings</mat-icon>{{ 'NAVBAR.SETTINGS' | translate }}</button>
            <button mat-menu-item [matMenuTriggerFor]="teamsMenu" *ngIf="session.user.teams.length > 1">
                <mat-icon>people</mat-icon>{{ 'NAVBAR.TEAMS' | translate }}
            </button>
            <button (click)="openInviteModal()" mat-menu-item><mat-icon>person_add</mat-icon>{{ 'NAVBAR.ADD_PLAYER' | translate }}</button>
            <button mat-menu-item (click)="createNewTeam()">
                <mat-icon>group_add</mat-icon> {{ 'NAVBAR.CHANGE_TEAM_NEW_TEAM' | translate }}
            </button>
            <button mat-menu-item [matMenuTriggerFor]="langMenu"><mat-icon>language</mat-icon>{{ 'NAVBAR.LANGUAGE' | translate }}</button>
            <button (click)="openAbout()" mat-menu-item><mat-icon>info</mat-icon>{{ 'NAVBAR.ABOUT_APP' | translate }}</button>
            <button (click)="logout()" mat-menu-item><mat-icon>exit_to_app</mat-icon>{{ 'NAVBAR.LOGOUT' | translate }}</button>
        </mat-menu>

        <mat-menu #langMenu="matMenu">
            <button mat-menu-item (click)="changeLanguage('en')"><span class="flag-icon gb me-2"></span>English</button>
            <button mat-menu-item (click)="changeLanguage('cs')"><span class="flag-icon cz me-2"></span>Česky</button>
        </mat-menu>
        <mat-menu #teamsMenu="matMenu">
            <button mat-menu-item (click)="switchTeam(t.id)" *ngFor="let t of session.user.teams">{{ t.name }}</button>
        </mat-menu>
    </div>

    <div class="d-none d-xxl-block">
        <button (click)="toggleSidebar()" *ngIf="notificationCount === 0; else notificationsWithBadge" mat-button class="notification-icon">
            <mat-icon>notifications</mat-icon>
        </button>
    </div>

    <div class="d-xxl-none">
        <button
            (click)="toggleSidebar()"
            *ngIf="notificationCount === 0; else notificationsWithBadgeLink"
            mat-button
            class="notification-icon"
        >
            <mat-icon>notifications</mat-icon>
        </button>
    </div>

    <ng-template #notificationsWithBadge>
        <button mat-button class="notification-icon clickable" (click)="toggleSidebar()">
            <mat-icon [matBadge]="notificationCount | badgeNumber">notifications_active</mat-icon>
        </button>
    </ng-template>
    <ng-template #notificationsWithBadgeLink>
        <button mat-button class="notification-icon clickable" (click)="toggleSidebar()">
            <mat-icon [matBadge]="notificationCount | badgeNumber">notifications_active</mat-icon>
        </button>
    </ng-template>
</div>
