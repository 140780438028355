import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { AppState } from 'store';
import {
    closeSidebar,
    getNotificationCountSuccess,
    loadMatchInvitationsSuccess,
    loadNextNotificationsSuccess,
    loadNotificationsSuccess,
    markAsReadSuccess,
    openSidebar,
    toggleSidebar,
} from './sidebar.actions';
import { ISidebarState } from './sidebar.models';
const initialState: ISidebarState = {
    items: [],
    matchInvitations: [],
    opened: false,
    notifications: [],
    counts: {
        bellCount: 0,
        bellUnreadCount: 0,
        matchInvitationCount: 0,
    },
    bellNotificationsFilter: 'all',
};

const reducer = createReducer(
    initialState,
    on(toggleSidebar, (state) => ({ ...state, opened: !state.opened })),
    on(openSidebar, (state) => ({ ...state, opened: true })),
    on(closeSidebar, (state) => ({ ...state, opened: false })),
    on(loadMatchInvitationsSuccess, (state, { payload }) => ({ ...state, matchInvitations: payload })),
    on(loadNotificationsSuccess, (state, { values, filter }) => ({ ...state, notifications: values, bellNotificationsFilter: filter })),
    on(loadNextNotificationsSuccess, (state, { values }) => ({ ...state, notifications: [...state.notifications, ...values] })),
    on(getNotificationCountSuccess, (state, counts) => ({ ...state, counts: counts })),
    on(markAsReadSuccess, (state, counts) => ({ ...state, counts: counts })),
);

export function sidebarReducer(state: ISidebarState = initialState, action: Action): ISidebarState {
    return reducer(state, action);
}

const selectFeature = (state: AppState) => state.sidebar;
export const selectCounts = createSelector(selectFeature, (state) => state.counts);
export const selectFilter = createSelector(selectFeature, (state) => state.bellNotificationsFilter);
export const selectUnreadCount = createSelector(selectCounts, (counts) => counts.bellUnreadCount + counts.matchInvitationCount);
export const selectNotifications = createSelector(selectFeature, (state) => state.notifications);
