import { createAction, props } from '@ngrx/store';
import { BellNotificationCountModel, BellNotificationModel, MatchInvitationModel, MatchInvitationState } from 'models';
import { BellNotificationsFilterType } from './sidebar.models';

export const toggleSidebar = createAction('[SIDEBAR] toggle');
export const openSidebar = createAction('[SIDEBAR] open');
export const closeSidebar = createAction('[SIDEBAR] close');

export const loadMatchInvitations = createAction('[SIDEBAR] load match invitations', props<{ lastModified: any }>());
export const loadMatchInvitationsSuccess = createAction(
    '[SIDEBAR] load match invitations success',
    props<{ payload: Array<MatchInvitationModel> }>(),
);

export const loadNotifications = createAction('[SIDEBAR] Load notifications', props<{ filter?: BellNotificationsFilterType }>());
export const loadNotificationsSuccess = createAction(
    '[SIDEBAR] Load notifications success',
    props<{ values: Array<BellNotificationModel>; filter: BellNotificationsFilterType }>(),
);

export const loadNextNotifications = createAction('[SIDEBAR] Load next notifications');
export const loadNextNotificationsSuccess = createAction(
    '[SIDEBAR] Load next notifications success',
    props<{ values: Array<BellNotificationModel> }>(),
);

export const getNotificationCount = createAction('[SIDEBAR] Get notification count');
export const getNotificationCountSuccess = createAction('[SIDEBAR] Get notification count success', props<BellNotificationCountModel>());

export const markAsRead = createAction('[SIDEBAR] Mark notifications as read', props<{ ids: Array<string> }>());
export const markAsReadSuccess = createAction('[SIDEBAR] Mark notifications as read success', props<BellNotificationCountModel>());

export const markAllAsRead = createAction('[SIDEBAR] Mark all notifications as read');

export const changeMatchInvitationState = createAction(
    '[SIDEBAR] change match invitation state',
    props<{ id: string; state: MatchInvitationState }>(),
);
