<ng-template #score>
    <div class="d-flex flex-row align-self-center">
        <span class="count" [class.dark]="pointsTeamA > pointsTeamB">{{ pointsTeamA }}</span>
        <div class="colon">:</div>
        <span class="count" [class.dark]="pointsTeamA < pointsTeamB">{{ pointsTeamB }}</span>
    </div>
</ng-template>

<div class="l-match">
    <div class="match-date" *ngIf="first">
        {{ 'MATCH_BOX.CURRENTLY_PLAYING' | translate }}
    </div>
    <div class="d-flex align-items-center justify-content-center w-100">
        <div class="avatar d-none d-md-flex justify-content-center align-items-center">
            <div>{{ match?.expectedOutcome?.expectedOutcome * 100 | number: '1.0-0' }} %</div>
        </div>
        <div class="line">
            <div class="match-grid align-items-center" *ngIf="match.settings.gameMode === gameMode.OneAgainstOne">
                <div class="name">
                    {{ match.teamA[0].displayName }}
                </div>

                <ng-container *ngTemplateOutlet="score"></ng-container>

                <div class="name">
                    {{ match.teamB[1].displayName }}
                </div>
            </div>

            <div class="match-grid" *ngIf="match.settings.gameMode === gameMode.TwoAgainstTwo">
                <div class="names-grid-1 justify-content-center">
                    <span class="roles">
                        <i class="fa-xs fas" [ngClass]="getRoleIcon(s, match.teamA[0])" *ngFor="let s of match.sets"></i>
                    </span>
                    <div class="name">
                        {{ match.teamA[0].displayName }}
                    </div>
                    <span class="roles">
                        <i class="fa-xs fas" [ngClass]="getRoleIcon(s, match.teamA[1])" *ngFor="let s of match.sets"></i>
                    </span>
                    <div class="name">
                        {{ match.teamA[1].displayName }}
                    </div>
                </div>

                <ng-container *ngTemplateOutlet="score"></ng-container>

                <div class="names-grid-2 justify-content-center">
                    <div class="name">
                        {{ match.teamB[0].displayName }}
                    </div>
                    <span class="roles">
                        <i class="fa-xs fas" [ngClass]="getRoleIcon(s, match.teamB[0])" *ngFor="let s of match.sets"></i>
                    </span>

                    <div class="name">
                        {{ match.teamB[1].displayName }}
                    </div>
                    <span class="roles">
                        <i class="fa-xs fas" [ngClass]="getRoleIcon(s, match.teamB[1])" *ngFor="let s of match.sets"></i>
                    </span>
                </div>
            </div>
        </div>

        <div class="avatar d-none d-md-flex justify-content-center align-items-center">
            <div>{{ (1 - match?.expectedOutcome?.expectedOutcome) * 100 | number: '1.0-0' }} %</div>
        </div>
    </div>
    <div class="l-score">
        <div
            class="score clickable"
            *ngFor="let ma of match.sets; let setIx = index"
            [class.selected]="setIx === selectedSet"
            (click)="selectedSet = setIx"
        >
            <div class="score_numbers">
                <span class="count score" [class.dark]="ma.teamA.score > ma.teamB.score">{{ ma.teamA.score }}</span>
                <div class="colonSmall">:</div>
                <span class="count score" [class.dark]="ma.teamA.score < ma.teamB.score">{{ ma.teamB.score }}</span>
            </div>
        </div>
    </div>

    <h4 class="my-3" *ngIf="selectedSet >= 0 && match.sets[selectedSet]?.goals.length">
        {{ 'DASHBOARD.MATCHBOX_SET_SET' | translate: { set: selectedSet + 1 } }}
    </h4>
    <app-ongoing-match-box-goals
        style="width: 100%; max-width: 600px"
        [match]="match"
        [selectedSet]="selectedSet"
    ></app-ongoing-match-box-goals>
</div>
