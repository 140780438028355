import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LineChartModule } from '@swimlane/ngx-charts';
import { AppMaterialModule } from 'app-material.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AboutComponent } from './about/about.component';
import { AddNewMatchComponent } from './add-new-match/add-new-match.component';
import { LoadSavedMatchComponent } from './add-new-match/load-saved-match/load-saved-match.component';
import { MatchCourseComponent } from './add-new-match/match-course/match-course.component';
import { MatchScoreComponent } from './add-new-match/match-score/match-score.component';
import { GameModeComponent } from './add-new-match/new-match-players-mode/new-match-players-mode.component';
import { StartMatchSetComponent } from './add-new-match/start-match-set/start-match-set.component';
import { AllowPushNotificationsComponent } from './alerts/allow-push-notifications/allow-push-notifications.component';
import { UpdateInProgressAlertComponent } from './alerts/update-in-progress-alert/update-in-progress-alert.component';
import { ApiErrorModalComponent } from './api-error-modal/api-error-modal.component';
import { BottomMenuBarComponent } from './bottom-menu-bar/bottom-menu-bar.component';
import { CardOptionsSelectComponent } from './card-options-select/card-options-select.component';
import { ChipComponent } from './chip/chip.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FitTextDirective } from './directives/fit-text.directive';
import { IfInRoleDirective } from './directives/if-in-role.directive';
import { IfInTeamRoleDirective } from './directives/if-in-team-role.directive';
import { GiphModalComponent } from './giph-modal/giph-modal.component';
import { InviteFormComponent } from './invite-form/invite-form.component';
import { InviteModalComponent } from './invite-modal/invite-modal.component';
import { LoadingPanelComponent } from './loading-panel/loading-panel.component';
import { CommentAttachmentComponent } from './match-box/comment-attachment/comment-attachment.component';
import { CommentComponent } from './match-box/comment/comment.component';
import { MatchBoxAchievementsComponent } from './match-box/match-box-achievements/match-box-achievements.component';
import { MatchBoxEloPointsComponent } from './match-box/match-box-elo-points/match-box-elo-points.component';
import { MatchBoxGoalsComponent } from './match-box/match-box-goals/match-box-goals.component';
import { MatchBoxHeaderOneAgainstOneComponent } from './match-box/match-box-header-one-against-one/match-box-header-one-against-one.component';
import { MatchBoxHeaderTwoAgainstTwoComponent } from './match-box/match-box-header-two-against-two/match-box-header-two-against-two.component';
import { MatchBoxComponent } from './match-box/match-box.component';
import { UpsertCommentComponent } from './match-box/upsert-comment/upsert-comment.component';
import { UploadingCommentAttachmentComponent } from './match-box/uploading-comment-attachment/uploading-comment-attachment.component';
import { MedalImgComponent } from './medal-img/medal-img.component';
import { OngoingMatchBoxGoalsComponent } from './ongoing-match-box/ongoing-match-box-goals/ongoing-match-box-goals.component';
import { OngoingMatchBoxComponent } from './ongoing-match-box/ongoing-match-box.component';
import { ParagonAvatarComponent } from './paragon-avatar/paragon-avatar.component';
import { BadgeNumberPipe } from './pipes/badge-number.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { PreserveNewlinePipe } from './pipes/preserve-newline.pipe';
import { SortDescPipe } from './pipes/sort-desc.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TimespanPipe } from './pipes/timespan.pipe';
import { TrophyPathPipe } from './pipes/trophy-path.pipe';
import { ScoreboardHistoryModalComponent } from './scoreboard-history-modal/scoreboard-history-modal.component';
import { EloScoreboardComponent } from './scoreboard/elo-scoreboard/elo-scoreboard.component';
import { MainScoreboardComponent } from './scoreboard/main-scoreboard/main-scoreboard.component';
import { OffenseDefenseScoreboardComponent } from './scoreboard/offense-defense-scoreboard/offense-defense-scoreboard.component';
import { TeamsScoreboardComponent } from './scoreboard/teams-scoreboard/teams-scoreboard.component';
import { SeasonSelectComponent } from './season-select/season-select.component';
import { SendInvitationModalComponent } from './send-invitation-modal/send-invitation-modal.component';
import { MatchInvitationCardComponent } from './sidebar/match-invitation-card/match-invitation-card.component';
import { NotificationCardComponent } from './sidebar/notification-card/notification-card.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ListCheckItemComponent } from './subscribe-to-push-notification-modal/list-check-item/list-check-item.component';
import { SubscribeToPushNotificationModalComponent } from './subscribe-to-push-notification-modal/subscribe-to-push-notification-modal.component';
import { SwitchTeamModalComponent } from './switch-team-modal/switch-team-modal.component';
import { TopbarComponent } from './topbar/topbar.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        AppMaterialModule,
        TranslateModule,
        InfiniteScrollModule,
        ReactiveFormsModule,
        PickerModule,
        SweetAlert2Module.forChild(),
        LineChartModule,
    ],
    exports: [
        ReactiveFormsModule,
        TopbarComponent,
        MomentPipe,
        ParagonAvatarComponent,
        MatchBoxComponent,
        OngoingMatchBoxComponent,
        TranslateModule,
        IfInRoleDirective,
        PreserveNewlinePipe,
        GiphModalComponent,
        SidebarComponent,
        ChipComponent,
        AboutComponent,
        SortPipe,
        SortDescPipe,
        CommonModule,
        MedalImgComponent,
        InitialsPipe,
        SeasonSelectComponent,
        FitTextDirective,
        AllowPushNotificationsComponent,
        UpdateInProgressAlertComponent,
        InviteFormComponent,
        LoadingPanelComponent,
        MainScoreboardComponent,
        TeamsScoreboardComponent,
        OffenseDefenseScoreboardComponent,
        TimespanPipe,
        IfInTeamRoleDirective,
        EloScoreboardComponent,
        BottomMenuBarComponent,
        BadgeNumberPipe,
        CardOptionsSelectComponent,
    ],
    declarations: [
        TopbarComponent,
        MomentPipe,
        AddNewMatchComponent,
        ParagonAvatarComponent,
        MatchBoxComponent,
        OngoingMatchBoxComponent,
        IfInRoleDirective,
        SendInvitationModalComponent,
        PreserveNewlinePipe,
        GiphModalComponent,
        InviteModalComponent,
        SidebarComponent,
        ChipComponent,
        AboutComponent,
        SortPipe,
        SortDescPipe,
        ApiErrorModalComponent,
        CommentComponent,
        UpsertCommentComponent,
        MedalImgComponent,
        ConfirmDialogComponent,
        MatchCourseComponent,
        MatchScoreComponent,
        InitialsPipe,
        MatchBoxGoalsComponent,
        OngoingMatchBoxGoalsComponent,
        SeasonSelectComponent,
        FitTextDirective,
        NotificationCardComponent,
        StartMatchSetComponent,
        AllowPushNotificationsComponent,
        UpdateInProgressAlertComponent,
        InviteFormComponent,
        LoadingPanelComponent,
        MainScoreboardComponent,
        TeamsScoreboardComponent,
        OffenseDefenseScoreboardComponent,
        TimespanPipe,
        IfInTeamRoleDirective,
        LoadSavedMatchComponent,
        MatchInvitationCardComponent,
        SubscribeToPushNotificationModalComponent,
        ListCheckItemComponent,
        ScoreboardHistoryModalComponent,
        MatchBoxAchievementsComponent,
        TrophyPathPipe,
        EloScoreboardComponent,
        MatchBoxEloPointsComponent,
        SwitchTeamModalComponent,
        CardOptionsSelectComponent,
        BottomMenuBarComponent,
        GameModeComponent,
        MatchBoxHeaderOneAgainstOneComponent,
        MatchBoxHeaderTwoAgainstTwoComponent,
        BadgeNumberPipe,
        CommentAttachmentComponent,
        UploadingCommentAttachmentComponent,
    ],
})
export class SharedModule {}
