<div class="d-flex flex-column">
    <img *ngIf="imageUrl" [attr.src]="imageUrl" class="comment-img" />
    <mat-form-field>
        <textarea
            #textArea
            [ngModel]="text"
            (ngModelChange)="onTextChange($event)"
            (paste)="onPaste($event)"
            (drop)="onDrop($event)"
            rows="4"
            matInput
            [placeholder]="idParentComment() ? ('MATCH.REPLY_COMMENT_TOOLTIP' | translate) : ('MATCH.COMMENT_TOOLTIP' | translate)"
        ></textarea>
    </mat-form-field>
    <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-row gap-1">
            <mat-icon
                class="control-icon clickable"
                (click)="showEmoPicker = !showEmoPicker"
                [matTooltip]="'MATCH.COMMENT_EMOJI' | translate"
                [matTooltipShowDelay]="500"
                >sentiment_satisfied_alt</mat-icon
            >
            <mat-icon
                (click)="attachmentFileInput.click()"
                class="control-icon clickable"
                [matTooltip]="'MATCH.COMMENT_ATTACH_FILE' | translate"
                [matTooltipShowDelay]="500"
                >attach_file</mat-icon
            >
            <mat-icon
                class="control-icon clickable"
                (click)="addGif()"
                [matTooltip]="'MATCH.COMMENT_ADD_GIF' | translate"
                [matTooltipShowDelay]="500"
                >gif</mat-icon
            >

            <emoji-mart
                [style]="{ position: 'absolute', 'z-index': 1000 }"
                *ngIf="showEmoPicker"
                title="Pick your emoji…"
                emoji="point_up"
                [isNative]="true"
                (emojiSelect)="onEmojiSelect($event)"
            ></emoji-mart>

            <input
                type="file"
                title="attachment"
                (change)="onAttachmentFileChange($event)"
                class="d-none"
                #attachmentFileInput
                accept="video/*,image/*"
                multiple="true"
            />
        </div>

        <button class="ms-auto" (click)="sentComment()" color="primary" mat-raised-button [disabled]="!!uploadingAttachments.length">
            @if (idParentComment()) {
                {{ 'MATCH.REPLY_COMMENT_SEND' | translate }}
            } @else {
                {{ 'MATCH.COMMENT_SEND' | translate }}
            }
        </button>
    </div>
    <div class="row" *ngIf="attachments.length">
        <app-comment-attachment
            *ngFor="let attachment of attachments"
            [attachment]="attachment"
            [class.col-lg-6]="attachment.contentType.startsWith('image')"
            [class.col-lg-12]="attachment.contentType.startsWith('video')"
        >
        </app-comment-attachment>
    </div>
    <div class="d-flex flex-row flex-wrap gap-2 mb-4" *ngIf="uploadingAttachments.length">
        <app-uploading-comment-attachment
            *ngFor="let attachment of uploadingAttachments"
            [attachment]="attachment"
        ></app-uploading-comment-attachment>
    </div>
</div>
