<div class="card m-2" *ngIf="matchInvatation">
    <div class="card-body">
        <h5 class="card-title">
            {{ matchInvatation.inviteMessage || 'ADD_MATCH.DEFAULT_MESSAGE' | translate: { name: matchInvatation.player } }}
        </h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ matchInvatation.createdAt | moment: 'fromNow' }}</h6>
        <div class="card-text">
            <div *ngFor="let p of matchInvatation.players; let ix = index">
                <app-chip
                    [id]="matchInvatation.id + '_' + ix"
                    [selected]="getBooleanState(p.state)"
                    [inverted]="true"
                    [color]="p.state === matchInvitationPlayerState.ReservePlayer ? 'blue' : null"
                    >{{ p.displayName }}</app-chip
                >
                <hr class="divider" *ngIf="showDivider(ix)" />
            </div>
        </div>
        <div class="d-flex flex-row flex-wrap gap-2">
            <button type="button" (click)="startMatch()" *ngIf="showStart" class="btn btn-sm btn-success">
                <i class="fas fa-play"></i>
                {{ 'SIDEBAR.MATCH_INVITATION_START' | translate }}
            </button>
            <button (click)="changePlayerState(matchInvitationPlayerState.Accepted)" *ngIf="showJoin" class="btn btn-sm btn-success">
                <i class="fas fa-user-plus"></i>
                {{ 'SIDEBAR.MATCH_INVITATION_JOIN' | translate }}
            </button>
            <button
                (click)="changePlayerState(matchInvitationPlayerState.ReservePlayer)"
                class="btn btn-sm btn-warning"
                *ngIf="showReserve"
            >
                <i class="fas fa-question"></i> {{ 'SIDEBAR.MATCH_INVITATION_RESERVE_PLAYER' | translate }}
            </button>
            <button (click)="changePlayerState(matchInvitationPlayerState.Refused)" class="btn btn-sm btn-danger" *ngIf="showRefuse">
                <i class="fas fa-minus-circle"></i> {{ 'SIDEBAR.MATCH_INVITATION_REFUSE' | translate }}
            </button>
            <button *ngIf="idPlayer === matchInvatation.idPlayer" [swal]="deleteSeasonSwal" class="btn btn-sm btn-danger">
                <i class="fas fa-ban"></i> {{ 'SIDEBAR.MATCH_INVITATION_CANCEL' | translate }}
            </button>
            <button *ngIf="idPlayer === matchInvatation.idPlayer" (click)="openInvitationModal()" class="btn btn-sm btn-primary">
                <i class="fas fa-pen"></i> {{ 'SIDEBAR.MATCH_INVITATION_EDIT' | translate }}
            </button>

            <swal
                #deleteSeasonSwal
                [title]="'SIDEBAR.MATCH_INVITATION_CANCEL_CONFIRM_TITLE' | translate"
                [confirmButtonText]="'COMMON.OK' | translate"
                [cancelButtonText]="'COMMON.CANCEL' | translate"
                [showCancelButton]="true"
                [icon]="'question'"
                (confirm)="cancel()"
                [confirmButtonColor]="'#96242f'"
            >
            </swal>
        </div>
    </div>
</div>
