<div class="btn-group btn-group-sm" role="group">
    <button
        type="button"
        class="btn"
        [ngClass]="{
            'btn-outline-success': value !== gameMode.OneAgainstOne,
            'btn-success': value === gameMode.OneAgainstOne
        }"
        (click)="valueChange.emit(gameMode.OneAgainstOne)"
    >
        1v1
    </button>
    <button
        type="button"
        class="btn"
        [ngClass]="{
            'btn-outline-success': value !== gameMode.TwoAgainstTwo,
            'btn-success': value === gameMode.TwoAgainstTwo
        }"
        (click)="valueChange.emit(gameMode.TwoAgainstTwo)"
    >
        2v2
    </button>
</div>
