<div class="chip">
    <div
        class="l-fsChips w-100"
        [ngClass]="{
            checked: selected === true,
            'not-checked': selected === false,
            undetermined: selected === undefined,
            inverted: inverted,
            blue: color === 'blue'
        }"
        [class.disabled]="disabled"
    >
        <label class="fsChips_check">
            <input
                #input
                class="fsChips_input"
                type="checkbox"
                [id]="id"
                [value]="selected"
                (change)="!disabled && selectedChange.emit(!!input.value)"
            />
            <span class="fsChips_span">
                <i
                    class="material-icons fsChips_icon fsChips_icon-check"
                    [ngClass]="{
                        checked: selected === true,
                        'not-checked': selected === false,
                        undetermined: selected === undefined,
                        inverted: inverted,
                        normal: !inverted
                    }"
                    >check</i
                >
                <i
                    class="material-icons fsChips_icon fsChips_icon-clear"
                    [ngClass]="{
                        checked: selected === true,
                        'not-checked': selected === false,
                        undetermined: selected === undefined,
                        inverted: inverted,
                        normal: !inverted
                    }"
                    >clear</i
                >
                <i
                    class="material-icons fsChips_icon fsChips_icon-question"
                    [ngClass]="{
                        checked: selected === true,
                        'not-checked': selected === false,
                        undetermined: selected === undefined,
                        inverted: inverted,
                        normal: !inverted
                    }"
                    >question_mark</i
                >
            </span>
        </label>
        <label class="fsChips_label" [for]="id"> <ng-content></ng-content> </label>
    </div>
</div>
