import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Session } from 'core/session.service';
import { GameMode, MatchInvitationCreateModel, MatchInvitationModel, MatchInvitationUpdateModel, TeamMemberRole } from 'models';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState, playersSelectors, sidebarActions } from 'store';
import { IPlayer } from 'store/players/players.model';

interface IPlayerExt extends IPlayer {
    selected: boolean;
    disabled: boolean;
}

export interface ISendInvitationModalContext {
    matchInvitation?: MatchInvitationModel;
    gameMode?: GameMode;
}
export interface ISendInvitationModalResult {
    message: string;
    players: Array<number>;
}

@Component({
    selector: 'app-send-invitation',
    templateUrl: './send-invitation-modal.component.html',
    styleUrls: ['./send-invitation-modal.component.scss'],
})
export class SendInvitationModalComponent implements OnInit, OnDestroy {
    #unsubscribe = new Subject<void>();

    public message = '';
    public defaultMessage: string;
    public players: Array<IPlayerExt>;

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly context: ISendInvitationModalContext,
        private readonly dialogRef: MatDialogRef<SendInvitationModalComponent, ISendInvitationModalResult>,
        private readonly translate: TranslateService,
        private readonly session: Session,
        private readonly store: Store<AppState>,
        private readonly http: HttpClient,
    ) {}

    ngOnInit() {
        this.message = this.defaultMessage = String(
            this.translate.instant('ADD_MATCH.DEFAULT_MESSAGE', { name: this.session.user.displayName }),
        );
        this.store
            .pipe(
                select(playersSelectors.selectPlayeblePlayers),
                map((res) =>
                    res.filter(
                        (x) =>
                            x.id !== this.session.user.idPlayer &&
                            (x.teamMemberRole === TeamMemberRole.Player || x.teamMemberRole === TeamMemberRole.Guest),
                    ),
                ),
                takeUntil(this.#unsubscribe),
            )
            .subscribe((players) => {
                this.players = players
                    .filter((x) => x.user.email)
                    .map((x) => {
                        const selected = this.context.matchInvitation
                            ? this.context.matchInvitation.players.some((p) => p.idPlayer === x.id)
                            : false;
                        return {
                            ...x,
                            selected: selected,
                            disabled: selected,
                        };
                    });
            });
    }

    ngOnDestroy(): void {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }

    public allSelected() {
        return this.players.every((x) => x.selected);
    }

    public selectAllChanged() {
        if (this.allSelected()) {
            this.players.forEach((x) => (x.selected = false));
        } else {
            this.players.forEach((x) => (x.selected = true));
        }
    }

    public saveDisabled() {
        if (this.context.gameMode === GameMode.OneAgainstOne) {
            return this.players.filter((x) => x.selected).length < 1;
        } else {
            return this.players.filter((x) => x.selected).length < 3;
        }
    }

    onSave(form: NgForm) {
        if (this.saveDisabled()) {
            return;
        }
        if (form.valid) {
            this.message = this.message === this.defaultMessage ? null : this.message;
            const selectedPlayers = this.players.filter((x) => x.selected).map((x) => x.id);

            if (this.context.matchInvitation) {
                const body: MatchInvitationUpdateModel = {
                    message: this.message,
                    state: this.context.matchInvitation.state,
                    newPlayers: selectedPlayers.filter((x) => !this.context.matchInvitation.players.some((p) => p.idPlayer === x)),
                };
                this.http.patch<never>(`/api/match-invitations/${this.context.matchInvitation.id}`, body).subscribe(() => {
                    this.closeDialog();
                });
            } else {
                const body: MatchInvitationCreateModel = {
                    message: this.message,
                    players: selectedPlayers,
                    gameMode: this.context.gameMode || GameMode.TwoAgainstTwo,
                };
                this.http.post<MatchInvitationModel>('/api/match-invitations', body).subscribe(() => {
                    this.closeDialog();
                });
            }
        }
    }

    private closeDialog() {
        this.store.dispatch(sidebarActions.getNotificationCount());
        this.store.dispatch(sidebarActions.openSidebar());
        this.dialogRef.close();
    }
}
